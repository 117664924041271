import { Category } from './Operations'
import { Role } from './Role'
import { ColumnProps, OrderTableBy } from './Tables'

export type UserData = {
  _id: string
  channel?: string
  type: string
  firstName: string
  lastName: string
  lastAccess: string
  fullName: string
  createdBy: string
  createdAt: string
  updatedBy: string
  updatedAt: string
  pictureUrl: string
  curriculumVitaeUrl: string
  rfc: string
  curp: string
  primaryEmailAddress: string
  secondaryEmailAddress?: string
  primaryPhoneNumber: string
  secondaryPhoneNumber?: string
  linkedinUrl: string
  address: string
  postalCode: string
  colony: string
  city: string
  state: string
  country: string
  activated: boolean
  roles: Role[]
  bank?: string
  interbankKey?: string
  accountHolder?: string
  area?: string
  token?: string
  initials?: string
  onlyAssistant?: boolean
  code?: string
  name?: string
  group?: boolean
  matriculationNumber?: number
  addedBySearch?: boolean
  statusUpdated?: boolean
  accredibleCertificates?: any[]
}

export type GetUserData = {
  users: UserData[]
  count: number
}

export type UserParams = {
  page: number
  limit: number
  type: string
  keyword?: string
  activated?: boolean
  neverEntered?: boolean
  sortProperty?: string
  sortOrder?: OrderTableBy
}

export type ActivedBodyUsers = {
  userIds: string[]
  activated: boolean
}

export type UsersAtributesData = {
  name: string
  type: string
  title: string
  newUser: string
  placeholderSearch: string
  columns: ColumnProps[]
  category: Category
}

export const UserDataInitial: UserData = {
  _id: '',
  type: '',
  firstName: '',
  lastName: '',
  lastAccess: '',
  fullName: '',
  createdBy: '',
  createdAt: '',
  updatedBy: '',
  updatedAt: '',
  pictureUrl: '',
  curriculumVitaeUrl: '',
  rfc: '',
  curp: '',
  primaryEmailAddress: '',
  secondaryEmailAddress: '',
  primaryPhoneNumber: '',
  secondaryPhoneNumber: '',
  linkedinUrl: '',
  address: '',
  postalCode: '',
  colony: '',
  city: '',
  state: '',
  country: '',
  activated: false,
  roles: [],
  token: '',
  code: '',
}

export const translateTypeUser: any = {
  professor: 'Profesor',
  student: 'Alumno',
  staff: 'Staff',
  default: 'Usuario',
}
